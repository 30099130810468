'use strict';

import $ from 'jquery';
import Accordion from '../../atoms/accordion/accordion';
export default class Product {
  constructor() {

    if($('.product').length) {

      // Initialize accordion
      new Accordion($('.product__measurementsAcc'));


      /* Show subpages without reloading the page
        Refer: https://css-tricks.com/using-the-html5-history-api/
      */

      let $container = $('.product__subPages'),
      $contentWrapper = $('.product__content'),
      $links = $('a', $container);


      function requestContent(file){
        $contentWrapper.load(file + ' .product__content', function() {
          new Accordion($('.product__measurementsAcc'), true); /* reint accordion on ajax content */
          window.emitter.emit('initSlick'); /* this emitter is defined in the quick-enquiry-form.js */
        });


      }

      function removeCurrentClass(){
        $links.removeClass('current');
      }

      function addCurrentClass(elem){
        removeCurrentClass();
        $("." + elem).addClass('current');
      }

      $container.on('click', function(e){
        if(e.target != e.currentTarget){
          e.preventDefault();
          let linkName =  $(e.target).data('name'),
              pageTitle =  $(e.target).attr('title'),
              url = $(e.target).attr('href');

          addCurrentClass(linkName);
          history.pushState(linkName, null, url);
          requestContent(url);
          document.title = pageTitle;
        }
        e.stopPropagation();
      });


      $(window).on('popstate', function(e){
        let character = e.state,
            oldUrl,
            pageTitle;

        if (character == null) {
          character = $container.find('a:first-child').attr('data-name');
        }
        oldUrl = $('.'+ character).attr('href');
        pageTitle = $('.'+ character).attr('title');

        requestContent(oldUrl);
        addCurrentClass(character);
        document.title = pageTitle;

      });

      /* Measurements section
      */

      $contentWrapper.on('click', '.product__measurementsBtn', function() {
         let $measurements = $('.product__measurements', $contentWrapper),
          $measurementsBtn = $('.product__measurementsBtn', $contentWrapper),
          $measurementsTable = $('table', $measurements);

        let value = $(this).val();
        $measurementsBtn.removeClass('current');
        $(this).addClass('current');
        if(value == 'imperial') {
          $measurementsTable.find('td:nth-child(2)').css('display', 'none');
          $measurementsTable.find('td:nth-child(3)').css('display', 'block');
        } else {
          $measurementsTable.find('td:nth-child(2)').css('display', 'block');
          $measurementsTable.find('td:nth-child(3)').css('display', 'none');
        }
      });

    }

  }
}

// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import objectFitImages from 'object-fit-images';
import matchHeight from 'jquery-match-height';
import Emitter from 'tiny-emitter';
import 'fancybox';

import AOS from 'aos';
import easyAutocomplete from 'easy-autocomplete';

import { preventSelectizeTyping } from '../_scripts/_helper';


import HamburgerMenuBtn from '../_modules/atoms/hamburger-menu-btn/hamburger-menu-btn';
import Rte from '../_modules/atoms/rte/rte';
import Accordion from '../_modules/atoms/accordion/accordion';
import SearchButton from '../_modules/atoms/search-button/search-button';
import SearchBox from '../_modules/atoms/search-box/search-box';
import ImgList from '../_modules/atoms/img-list/img-list';
import StickyWidget from '../_modules/atoms/sticky-widget/sticky-widget';

import TopnavMenu from '../_modules/molecules/topnav-menu/topnav-menu';
import PrimaryMenu from '../_modules/molecules/primary-menu/primary-menu';
import HeaderCta from '../_modules/molecules/header-cta/header-cta';
import Sidebar from '../_modules/molecules/sidebar/sidebar';
import GallerySlider from '../_modules/molecules/gallery-slider/gallery-slider';
import EventListCard from '../_modules/molecules/event-list-card/event-list-card';
import SearchFormResults from '../_modules/molecules/search-form-results/search-form-results';

import SiteHeader from '../_modules/organisms/site-header/site-header';
import SiteFooter from '../_modules/organisms/site-footer/site-footer';
import HomeBanner from '../_modules/organisms/home-banner/home-banner';
import HomeContents from '../_modules/organisms/home-contents/home-contents';
import Product from '../_modules/organisms/product/product';
import Contact from '../_modules/organisms/contact/contact';
import Notifications from '../_modules/organisms/notifications/notifications';
import NewsContent from '../_modules/organisms/news-content/news-content';
import Standard from '../_modules/organisms/standard/standard';
import RelatedContent from '../_modules/organisms/related-content/related-content';
import RelatedEvents from '../_modules/organisms/related-events/related-events';
import NewsListing from '../_modules/organisms/news-listing/news-listing';
import GeneralListing from '../_modules/organisms/general-listing/general-listing';
import LandingPage from '../_modules/organisms/landing-page/landing-page';
import ResourceListing from '../_modules/organisms/resource-listing/resource-listing';
import ImportConditions from '../_modules/organisms/import-conditions/import-conditions';
import PetImportRequirementCalculator from '../_modules/organisms/pet-import-requirement-calculator/pet-import-requirement-calculator';
import Sitemap from '../_modules/organisms/sitemap/sitemap';
import DynamicFeedbackForm from '../_modules/organisms/dynamic-feedback-form/dynamic-feedback-form';

$(() => {

  window.emitter = new Emitter();

  $.fn.matchHeight._afterUpdate = function(event, groups) {

    // only init AOS after matchHeight has been fully initialised
    AOS.init({
      duration: 500,
    });

  }

  new HeaderCta();
  new HamburgerMenuBtn();
  new TopnavMenu();
  new PrimaryMenu();
  new SiteHeader();
  new GallerySlider();
  new HomeBanner();
  new HomeContents();
  new Rte();
  new Sidebar();
  new Product();
  new Contact();
  new Notifications();
  new Accordion();
  new NewsContent();
  new SiteFooter();
  new Standard();
  new RelatedContent();
  new SearchButton();
  new RelatedEvents();
  new NewsListing();
  new SearchBox();
  new LandingPage();
  new objectFitImages(); // activate object fit
  new EventListCard();
  new ResourceListing();
  new SearchFormResults();
  new ImportConditions();
  new PetImportRequirementCalculator();
  new GeneralListing();
  new ImgList();
  new Sitemap();

  if ($('.feedback-form').length) {
    new DynamicFeedbackForm();
  }


  if ($('.sticky-widget__container').length) {
    new StickyWidget();
  }


  /* Jquery function to run smooth scrolling for anchor links like #goto.html */
  $('a[href*="#"]:not([href="#"])').click(function(e) {
    e.preventDefault();
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
    || location.hostname == this.hostname) {

      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });

  $('.matchHeightInner').matchHeight(); // Matchheight inner elements
  $('.matchHeight').matchHeight(); // Matchheight outer elements

  preventSelectizeTyping();

});

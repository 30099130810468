'use strict';

import $ from 'jquery';
import selectize from 'selectize';

import { areAllSelectsFilled } from '../../../_scripts/_helper';


export default class ImportConditions {
  constructor() {

    let importConditionsContainer = $('.import-conditions'),
    allSelect = $('.import-conditions__select'),
    importConditionsSearch = importConditionsContainer.find('.filter-button__search'),
    importConditionsObj = {},
    defaultOption = '<option selected="selected" value="">Select Item</option>';


    allSelect.each(function(i, each) {

      $(each).selectize({
        persist: false,
        create: false,
        onInitialize: function() {
          this.clear(1);
        },
        onChange: function(value) {

          let $this = this.$input,
          endpoint = $this.data('endpoint'),
          identifier = $this.attr('id'),
          selectValue = value;

          let nextSelect = $this.closest('.import-conditions__each').next().find('select');

          emptyAllSubsequentSelects($this);

          handleDynamicOptions(endpoint, identifier, selectValue, nextSelect);

        }
      });

    })

    // allSelect.selectize({
    //   persist: false,
    //   create: false,
    //   onInitialize: function() {
    //     var $select = $('#productType').selectize();
    //     var control = $select[0].selectize;
    //     control.clear();
    //   },
    //   onChange: function(value) {
    //
    //     let $this = this.$input,
    //     endpoint = $this.data('endpoint'),
    //     identifier = $this.attr('id'),
    //     selectValue = value;
    //
    //     let nextSelect = $this.closest('.import-conditions__each').next().find('select');
    //
    //     emptyAllSubsequentSelects($this);
    //
    //     handleDynamicOptions(endpoint, identifier, selectValue, nextSelect);
    //
    //   }
    // });


    importConditionsSearch.click(function(event) {
      event.preventDefault();

      let url = window.location.origin;

      let checkSelects = areAllSelectsFilled(allSelect);

      if (checkSelects) {
        // window location replace to the new page
        let destination = importConditionsContainer.data('destination');

        url += `${destination}/`;

        $.each(importConditionsObj, function(key, value) {

          url += `&${key}=${value}`;
        });

        url = url.replace('/&', '/?');

        window.location.href = url;
      }
    });


    function emptyAllSubsequentSelects(currentSelect) {

      let canEmpty = false;

      allSelect.each(function(idx, each) {

        let eachInstance = each.selectize,
        $each = $(each);

        if (canEmpty) {
          // empty this current instance of its options
          eachInstance.clear();
          eachInstance.clearOptions();

        }

        if ($each.attr('id') == currentSelect.attr('id')) {
          canEmpty = true;
        };

      })

    }

    // helper
    function handleDynamicOptions(url, id, option, nextSelect) {

      // update importConditionsObj for search button trigger later
      importConditionsObj[id] = option;

      let nextSelectInstance;

      if (nextSelect.length) {
        nextSelectInstance = nextSelect[0].selectize;
      }


      $.ajax({
        url: url,
        method: 'GET',
        dataType: 'json',
        data: {
          option: option
        },
        success: function(data) {

          // construct options
          let optionsHtml = defaultOption,
          arrLength = data.length,
          valueToTrigger;


          if (nextSelectInstance) {
            nextSelectInstance.clear();
            nextSelectInstance.clearOptions();
            nextSelectInstance.addOption(data);
          }


          if (arrLength == 1) {

            valueToTrigger = data[0].value;


            let nextEndpoint = nextSelect.data('endpoint'),
            id = nextSelect.attr('id'),
            adjacentSelect = nextSelect.closest('.import-conditions__each').next().find('select.import-conditions__select');

            setTimeout(function() {

              if (nextSelectInstance) {
                nextSelectInstance.setValue(valueToTrigger);
              }

              if (adjacentSelect) {
                handleDynamicOptions(nextEndpoint, id, valueToTrigger, adjacentSelect);
              }
              
            }, 500);




          }


        },
        error: function(error) {

          if (nextSelectInstance) {
            nextSelectInstance.clear();
            nextSelectInstance.clearOptions();
          }


        }
      })

    }




  }
}

'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class StickyWidget {
  constructor() {

    let $sharerPlatforms = $('.sharer-platforms'),
    $stickyWidgetShare = $('.sticky-widget__share');


    enquire.register("screen and (max-width:580px)", {
      match : function() {


        // clicking sticky widget to show/hide social media share buttons
        $stickyWidgetShare.click(function() {

          $sharerPlatforms.toggleClass('show');

        });


        // clicking outside to close social media share buttons
        $(document).mouseup(function(e)
        {
          var container = $stickyWidgetShare;

          // if the target of the click isn't the container nor a descendant of the container
          if (!container.is(e.target) && container.has(e.target).length === 0)
          {
            
            $sharerPlatforms.removeClass('show');
          }
        });

      }
    });
  }
}

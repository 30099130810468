'use strict';

import $ from 'jquery';

export default class HeaderCta {
  constructor() {
    $('.quick-enquiry-btn').on('click', function() {
      window.emitter.emit('showQuickEnquiry'); /* this emitter is defined in the quick-enquiry-form.js */
    });
  }
}

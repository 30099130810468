import $ from 'jQuery';
import 'bootstrap/dist/js/bootstrap.bundle'
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle';

'use strict';


export default class Standard {
  constructor() {
    
    var linkID = document.getElementById("securedLink_AVS");

    $("#securedLink_AVS").on('click', function(e)
  {
    e.preventDefault();
    console.log('securedLink_AVS clicked');
    var url = $(this).attr('href');
    console.log(url);
    openModalDialog(url);    
    $('#securedDocModalWindow').modal('show');

  });  

  function  openModalDialog(url) {
    var modalHTML = document.createElement('html');
    var modalHead = document.createElement('head');
    var modalBody = document.createElement('body');
    var modalDIV = document.createElement('div');
    var modalDIVID = document.createAttribute('id');
    modalDIVID.value = 'securedDocModalWindow';
    modalDIV.innerHTML = '<object type="text/html" data="'+ url +'" ></object>';
    modalDIV.append(modalDIVID);
    modalBody.append(modalDIV);
    modalHead.append(modalBody);
    modalHTML.append(modalHead);
    }
  }  
  
}



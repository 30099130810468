'use strict';

import $ from 'jquery';

export default class TopnavMenu {
  constructor() {
    let $searchBtn = $('.topnav__search'),
        $sisterSites = $('.sister-sites'),
        $sisterSitesSelected = $('.sister-sites__selected', $sisterSites),
        $sisterSitesDD = $('.sister-sites__DD', $sisterSites);

    // show searh box on clicking upon search icon on top nav
    $searchBtn.on('click', function(event) {
      event.stopPropagation();
      window.emitter.emit('showDesktopSearch', 'show'); // the emitter function is referenced in search-box atom
    });

    // Sister sites dropdown
    $sisterSitesSelected.on('click', () => $sisterSitesDD.toggleClass('active') );

    /* Hide Quick Enquiry when click on outside */
    $(document).on('mouseup', function(e) {
      if($sisterSitesDD.hasClass('active')) {
        if (!$sisterSites.is(e.target) && $sisterSites.has(e.target).length === 0) {
          $sisterSitesDD.removeClass('active');
        }
      }
    });
  }
}

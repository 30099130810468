'use strict';

import $ from 'jquery';

import { initFilters } from '../../../_scripts/_helper';


export default class GeneralListing {
  constructor() {

    let generalListingContainer = $('.general-listing');

    if (generalListingContainer.length) {
      initFilters(generalListingContainer);
    }

  }
}

'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class ImgList {
  constructor() {
    let imgList = $('.img-list'),
    imgListTitle = $('.img-list__title', imgList),
    accordionBtn = imgList.find('.icon');

    enquire.register("screen and (max-width: 1024px)", {

      // OPTIONAL
      // If supplied, triggered when a media query matches.
      match : function() {

        accordionBtn.on('click', function(e) {

          let $this = $(this),
          $currentInfo = $this.parent().siblings('.img-list__info');


          if ($currentInfo.hasClass('open')) {
            $currentInfo.removeClass('open');
            $this.removeClass('icon-minus');
            $this.addClass('icon-plus');

          } else {
            $currentInfo.addClass('open');
            $this.addClass('icon-minus');
            $this.removeClass('icon-plus');
          }
        });

      },

      // OPTIONAL
      // If supplied, triggered when the media query transitions
      // *from a matched state to an unmatched state*.
      unmatch : function() {


      }

    });

  }
}

'use strict';

import $ from 'jquery';

export default class PrimaryMenu {
  constructor() {
    let $nav = $(".main-menu"),
      $html = $('html'),
      levelOneLinks = $('.main-menu__lvl1-item'),
      levelOneHolders = $('.main-menu__lvl1-holder'),
      levelTwoContainers = $('.main-menu__lvl2');

    window.emitter.on('showMobileMenu', function(event) {
      if(event == 'show') {
        $nav.addClass('visible');
        $html.addClass('no-scroll');
      } else {
        $nav.removeClass('visible');
        $html.removeClass('no-scroll');

        levelOneHolders.removeClass('active');
        levelTwoContainers.removeClass('show');
      }
    });

    levelOneLinks.click(function() {

      let $this = $(this),
      secondLevelContainer = $this.find('.main-menu__lvl2'),
      levelOneHolder = $(this).find('.main-menu__lvl1-holder');

      secondLevelContainer.toggleClass('show');
      levelOneHolder.toggleClass('active');

    })

  }
}

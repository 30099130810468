'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class HomeContents {
  constructor() {

    let personaOptions = $('.persona__select').find('li'),
    personaBoxes = $('.persona__boxes');

    personaOptions.click(function(e) {
      e.preventDefault();

      personaOptions.removeClass('active');
      $(this).addClass('active');

      let category = $(this).data('category');

      $('.persona__category').removeClass('show');
      $(`.persona__category[data-show="${category}"]`).addClass('show');

    });

    enquire.register("screen and (max-width:1024px)", {

      // OPTIONAL
      // If supplied, triggered when a media query matches.
      match : function() {

        // $(".persona__resources, .persona__eservices").detach().appendTo('.persona__mobile-nonslick');
        let personaCategories = $('.persona__category');

        personaCategories.each(function(idx, each) {

          let $each = $(each),
          divsToDetach = $each.find(".persona__resources, .persona__eservices"),
          nonslickContainer = $each.find('.persona__mobile-nonslick');

          divsToDetach.detach().appendTo(nonslickContainer);
        })


        personaBoxes.slick({
          slidesToShow: 1,
          slideToScroll: 1,
          dots: true,
          arrows: false,
          speed: 400,
          centerMode: true,
          centerPadding: '15px',
          infinite: true,
          cssEase: 'ease',
        });
      },

      // OPTIONAL
      // If supplied, triggered when the media query transitions
      // *from a matched state to an unmatched state*.
      unmatch : function() {

        personaBoxes.slick('unslick');

      },

      // OPTIONAL
      // If supplied, triggered once, when the handler is registered.
      setup : function() {},

      // OPTIONAL, defaults to false
      // If set to true, defers execution of the setup function
      // until the first time the media query is matched
      deferSetup : true,

      // OPTIONAL
      // If supplied, triggered when handler is unregistered.
      // Place cleanup code here
      destroy : function() {}

    });

  }
}

'use strict';

import $ from 'jquery';
import 'leaflet';
import 'leaflet-editable';
import 'leaflet-draw';

import { retrieveOptions, renderOptions, hideContainer, showContainer, defaultOptionGenerator, generateForm, retrieveFormData, addClassToInput, validateEmail, validatePhoneNumber, removeClassFromInput } from '../../../_scripts/_helper';

export default class DynamicFeedbackForm {
  constructor() {


    /*******************************************************************/

    /** DEFINE MISCELLANEOUS **/
    let feedbackForm = $('.feedback-form__form'),
    feedbackFormFieldsContainer = $('.feedback-form__fields'),
    openFormBtn = $('.feedback-form__open'),
    step2 = $('#step2'),
    step3 = $('#step3'),
    step4 = $('#step4'),
    currentSubTopics = [],
    currentAnimalTypes = [],
    currentSpeciesWildAnimal = [],
    currentPrimaryIssues = [],
    currentNatureOfOffense = [],
    subjectContainer = $('#subject'),
    subtopicContainer = $('#sub-topic'),
    chosenSubject,
    chosenSubtopic,
    chosenCaseDetailsHint,
    chosenEnquiryText = '',
    isPrimaryIssueAvailable = false;

    /** DEFINE ALL feedback-form__each **/
    let animalType = $('#animal-type'),
    animalTypeOther = $('#animal-type-other'),
    speciesWildAnimal = $('#species-wild-animal'),
    speciesWildAnimalOther = $('#species-wild-animal-other'),
    primaryIssue = $('#primary-issue'),
    primaryIssueOther = $('#primary-issue-other');

    /** DEFINE ALL SELECTS **/
    let subjectSelect = subjectContainer.find('select'),
    subtopicSelect = subtopicContainer.find('select'),
    animalTypeSelect = animalType.find('select'),
    speciesWildAnimalSelect = speciesWildAnimal.find('select'),
    primaryIssueSelect = primaryIssue.find('select');

    /** DEFINE ALL ENDPOINTS **/
    let subjectMappingEndpoint = feedbackForm.data('subjectMapping'),
    formDataEndpoint = feedbackForm.data('form'),
    formSubmitEndpoint = feedbackForm.data('submitEndpoint');

    /** DEFINE ALL DEFAULT OPTIONS **/
    let subtopicsDefaultOption = defaultOptionGenerator('sub topic'),
    speciesWildAnimalDefaultOption = defaultOptionGenerator('species wild animal'),
    animalTypesDefaultOption = defaultOptionGenerator('animal type'),
    currentPrimaryIssuesDefaultOption = defaultOptionGenerator('primary issues');


    /** END OF DEFINITIONS **/

    /*******************************************************************/

    /** CALL ON LOAD **/
    if ($('.feedback-form').is(':visible')) {
      retrieveOptions(subjectMappingEndpoint, subjectSelect, 'Subject');

      clearAllOtherInputs();

    }

    /** WHEN SELECTING OPTIONS **/
    subjectSelect.change(function() {

      hideLaterForms();

      let subject = $(this).val();

      chosenSubject = subject;


      if (subject === 'Please select the relevant subject') {

        subtopicSelect.html('<option>Please select the relevant sub topic</option>');

      }


      $.ajax({
        type: 'GET',
        dataType: 'json',
        url: subjectMappingEndpoint,
        success: function(data) {


          data.forEach(function(each, idx) {

            let eachSubject = each.Subject,
            subtopicsArray;

            if (eachSubject === subject) {

              let caseDetailsHint1 = each.CaseDetailsHint,
              caseDetailsHint2 = each.SubcatCaseDetailsHint,
              enquiryText = each.EnquiryText; // prioritise this

              chosenEnquiryText = enquiryText;

              if (caseDetailsHint2) {
                chosenCaseDetailsHint = caseDetailsHint2;
              } else {
                chosenCaseDetailsHint = caseDetailsHint1;
              }

              addClassToInput(subtopicSelect, 'feedback-form__element');

              subtopicsArray = each.SubTopics;

              // assign array of subtopics to currentSubTopics, later to be used to find animal types
              currentSubTopics = subtopicsArray;

              // check if there are SubTopics
              if (currentSubTopics.length) {
                let subtopicsLen = subtopicsArray.length;

                renderOptions(subtopicsArray, 'SubTopic', subtopicSelect, subtopicsDefaultOption);

                if (subtopicsLen === 1) {

                  let optionToSelect = subtopicsArray[0],
                  theSubtopic = optionToSelect.SubTopic,
                  enquiryText = optionToSelect.EnquiryText;

                  chosenEnquiryText = enquiryText;

                  if (enquiryText.length) {
                    chosenEnquiryText = enquiryText;
                  }

                  chosenSubtopic = theSubtopic;

                  if (chosenEnquiryText.length) {
                    openFormBtn.text(chosenEnquiryText);
                  }

                  // select the only option in the list
                  $(`option[value="${theSubtopic}"]`).prop('selected', true);

                  handleSubtopicChange(subtopicSelect);

                }

              } else {

                hideContainer(subtopicContainer);

                chosenSubtopic = '';

                let caseDescriptionHint = each.CaseDescriptionHint;


                showContainer(step3);

              }

            }

          })

        },
        error: function(error) {
          console.log(error);
        }
      })

    });

    function handleSubtopicChange($ele) {

      // HADI START
      hideContainer(primaryIssue);
      hideContainer(primaryIssueOther);
      hideContainer(animalType);
      hideContainer(animalTypeOther);
      hideContainer(speciesWildAnimal);
      hideContainer(speciesWildAnimalOther);

      hideLaterForms();

      currentPrimaryIssues = [];


      // subtopic value
      let subtopic = $ele.val();

      chosenSubtopic = subtopic;


      currentSubTopics.forEach(function(each, idx) {

        // if subtopic matches those in currentSubTopics array
        if (each.SubTopic == subtopic) {


          let animalTypes = each.AnimalTypes, // get animalTypes (note: not all of them have animalTypes, so must check later)
          enquiryText = each.EnquiryText,
          caseDetails = each.CaseDetailsHint,
          primaryIssues = each.PrimaryIssues,
          natureOfOffenses = each.NatureOfOffenses;

          if (enquiryText.length) {
            chosenEnquiryText = enquiryText;
          }

          if (chosenEnquiryText.length) {
            openFormBtn.text(chosenEnquiryText);
          }

          if (natureOfOffenses) {
            if (natureOfOffenses.length) {
              currentNatureOfOffense = natureOfOffenses;
            }
          }



          currentPrimaryIssues = primaryIssues;
          currentAnimalTypes = animalTypes;


          // 1) if both primaryIssues and animalType not available
          if (!currentPrimaryIssues.length && !currentAnimalTypes.length) {

            isPrimaryIssueAvailable = false;

            showContainer(step3);

          } else if (currentAnimalTypes.length && !currentPrimaryIssues.length) {
            // 2) if animalType available but primaryIssues not available

            isPrimaryIssueAvailable = false;

            renderOptions(currentAnimalTypes, 'AnimalType', animalTypeSelect, animalTypesDefaultOption);

            hideContainer(speciesWildAnimal);
            hideContainer(speciesWildAnimalOther);

            showContainer(step2);

          } else if (currentPrimaryIssues.length && !currentAnimalTypes.length) {
            // 3) if primaryIssues available but animalType not available

            isPrimaryIssueAvailable = true;

            renderOptions(currentPrimaryIssues, 'PrimaryIssue', primaryIssueSelect, currentPrimaryIssuesDefaultOption);

            showContainer(step2);
            primaryIssue.addClass('show');


          } else if (currentPrimaryIssues.length && currentAnimalTypes.length) {
            // 4) both available

            isPrimaryIssueAvailable = true;

            renderOptions(currentAnimalTypes, 'AnimalType', animalTypeSelect, animalTypesDefaultOption);

            hideContainer(speciesWildAnimal);
            hideContainer(speciesWildAnimalOther);

            showContainer(step2);

          }

        }

      })
    }

    subtopicSelect.change(function() {

      let $selectEle = $(this);

      handleSubtopicChange($selectEle);


    });


    animalTypeSelect.change(function() {

      hideContainer(step4);
      hideContainer(step3);

      hideContainer(primaryIssue);
      hideContainer(primaryIssueOther);
      hideContainer(speciesWildAnimal);
      hideContainer(speciesWildAnimalOther);
      hideContainer(animalTypeOther);

      currentSpeciesWildAnimal = [];


      let animalType = $(this).val();

      currentAnimalTypes.forEach(function(type, j) {

        if (animalType === type.AnimalType) {

          let isAnimalOther = type.IsAnimalOther;


          if (animalType === 'Snake') {

            currentSpeciesWildAnimal = type.SpeciesWildAnimal;

            renderOptions(currentSpeciesWildAnimal, 'Snake', speciesWildAnimalSelect, speciesWildAnimalDefaultOption);

            speciesWildAnimal.addClass('show');


          } else {


            if (isAnimalOther) {

              showContainer(animalTypeOther);
              addClassToInput(animalTypeOther, 'feedback-form__element required');

            } else {
              hideContainer(animalTypeOther);
              removeClassFromInput(animalTypeOther, 'feedback-form__element required');
            };


            // showContainer(step3);

            if (!isPrimaryIssueAvailable) {

              showContainer(step3);

            } else {

              // primaryIssues available

              renderOptions(currentPrimaryIssues, 'PrimaryIssue', primaryIssueSelect, currentPrimaryIssuesDefaultOption);
              primaryIssue.addClass('show');


              hideContainer(step3);
              showContainer(step2);

            }


          }

        }

      })


    })


    speciesWildAnimalSelect.change(function() {

      hideContainer(step4);

      let speciesWildAnimalVal = $(this).val(),
      speciesWildAnimal = [];

      currentAnimalTypes.forEach(function(animalType, l) {

        if (animalType.AnimalType === 'Snake') {

          speciesWildAnimal = animalType.SpeciesWildAnimal;

        }

      });


      if (speciesWildAnimal.length) {

        speciesWildAnimal.forEach(function(species, m) {

          if (species.Snake === speciesWildAnimalVal) {

            let isSpeciesOther = species.IsSpeciesOther;

            if (isSpeciesOther) {

              showContainer(speciesWildAnimalOther);

            } else {

              hideContainer(speciesWildAnimalOther);

            }

            showContainer(step3);

          }

        });

      }

    });


    primaryIssueSelect.change(function() {


      let primaryIssue = $(this).val();


      currentPrimaryIssues.forEach(function(each, p) {


        if (primaryIssue == each.PrimaryIssue) {

          if (each.IsPrimaryOther) {

            showContainer(primaryIssueOther);
            addClassToInput(primaryIssueOther, 'feedback-form__element required');

          } else {

            hideContainer(primaryIssueOther);
            removeClassFromInput(primaryIssueOther, 'feedback-form__element required');

          }


          showContainer(step3);


        }

      });

    });

    openFormBtn.click(function(event) {
      event.preventDefault();

      feedbackFormFieldsContainer.html('');

      generateForm(formDataEndpoint, feedbackFormFieldsContainer, chosenSubject, chosenSubtopic, chosenCaseDetailsHint, currentNatureOfOffense);

      hideContainer(step3);
    });


    /** NATURE OF OFFENCE SELECT **/
    $(document).on('change', 'select[name="NatureOfOffense"]', function() {

      let natureOfOffenceVal = $(this).val();

      let selected = $(this).find('option:selected'),
      isOther = selected.data('other');

      let $otherField = $('#NatureOfOffenseOther');

      if (isOther) {

        $otherField.removeClass('hide');
        $otherField.find('.feedback-form__element').addClass('required');

      } else {

        $otherField.addClass('hide');
        $otherField.find('.feedback-form__element').removeClass('required');

      }

    });


    $(document).on('blur', 'input.feedback-form__element.required, textarea.feedback-form__element.required', function() {

      let $field = $(this),
      errorMsg = $field.data('error'),
      value = $field.val(),
      name = $field.data('param'),
      errorContainer = $field.siblings('.feedback-form__error');


      if (!value.length || value == false) {

        errorContainer.html(errorMsg).addClass('show');

      } else {

        errorContainer.removeClass('show');

        // check for email
        switch (name) {
          case 'Email':

          let emailValid = validateEmail(value);

          if (!emailValid) {

            errorContainer.html(errorMsg).addClass('show');

          } else {

            errorContainer.removeClass('show');

          }

          break;

          case 'Phone':

          let phoneValid = validatePhoneNumber(value);

          if (!phoneValid) {

            errorContainer.html(errorMsg).addClass('show');

          } else {
            errorContainer.removeClass('show');
          }

          break;

        }

      }

    });



    $(document).on('click', '.feedback-form__submit', function(e) {

      e.preventDefault();

      // SUBMIT ALL FROM FORMFIELDS
      let formFields = feedbackForm.find('.feedback-form__element'),
      requiredFields = feedbackForm.find('.feedback-form__element.required'),
      addressFields = feedbackForm.find('.feedback-form__address'),
      overallError = $('.feedback-form__overall-error'),
      validForSubmission = true,
      locationError = '',
      feedbackFormData = {};



      requiredFields.each(function(i, each) {

        let $each = $(each),
        errorMsg = $each.data('error'),
        value = $each.val(),
        name = $each.data('param'),
        errorContainer = $each.siblings('.feedback-form__error');


        if ($each.attr('name') == 'Location') {
          locationError = $each.data('error');
        }


        if (!value.length || value == false) {

          validForSubmission = false;

          errorContainer.html(errorMsg).addClass('show');

        } else {

          errorContainer.removeClass('show');

          // check for email
          switch (name) {
            case 'Email':

            let emailValid = validateEmail(value);

            if (!emailValid) {
              validForSubmission = false;

              errorContainer.html(errorMsg).addClass('show');
            } else {
              errorContainer.removeClass('show');
            }

            break;

            case 'Phone':

            let phoneValid = validatePhoneNumber(value);

            if (!phoneValid) {
              validForSubmission = false;
              errorContainer.html(errorMsg).addClass('show');
            } else {
              errorContainer.removeClass('show');
            }

            break;

          }

        }

      });



      // run only if map is showing
      if ($('#map').length) {

        let validAddressFields = [],
        locationErrorMessage = $('.map-input').find('.feedback-form__error');

        addressFields.each(function(idx, addressField) {

          let $addressField = $(addressField),
          value = $addressField.val();

          if (value != '') {

            validAddressFields.push(value);

          }

        });


        if (!validAddressFields.length) {

          locationErrorMessage.text(locationError).addClass('show');
          validForSubmission = false;
        } else {
          locationErrorMessage.removeClass('show');
        }
      }



      if (validForSubmission) {

        overallError.removeClass('show');
        $('form').submit();

      } else {
        // show overall error
        overallError.addClass('show');
      }


    });


    $(document).on('change', 'input[type="file"]', function(event) {

      var fileSize = this.files[0].size/1024/1024,
      name = this.files[0].name,
      ext = name.split('.').pop().toLowerCase(),
      formatsArray = ['gif','png','jpg','jpeg','svg'];

      if (fileSize > 9 || ($.inArray(ext, formatsArray) == -1)) {

        event.preventDefault();
        alert("Please upload only image files that are smaller than 9MB.");
        $(this).val('');

      } else {

        let thisParent = $(this).closest('.feedback-form__file'),
        fileName = thisParent.find('.feedback-form__filename'),
        uploadMoreBtn = thisParent.find('.feedback-form__uploadmore');


        fileName.html(`(${name})`);

        if (uploadMoreBtn.length) {
          uploadMoreBtn.addClass('show');
        }

      }

    });

    $(document).on('click', '.feedback-form__uploadmore', function(event) {

      event.preventDefault();

      let parent = $(this).closest('.feedback-form__file'),
      nextUploadContainer = parent.next();

      // HIDE UPLOAD BUTTON
      $(this).removeClass('show');

      // SHOW NEXT UPLOAD CONTAINER
      nextUploadContainer.addClass('show');

    });



    /** HELPER FUNCTIONS **/

    function clearAllOtherInputs() {

      $('#animal-type-other, #species-wild-animal-other, #primary-issue-other').val('');

    }

    function hideLaterForms() {

      hideContainer(step2);
      hideContainer(step3);
      hideContainer(step4);
      $('#animal-type, #animal-type-other, #species-wild-animal, #species-wild-animal-other, #primary-issue').removeClass('show');

    }

  }
}

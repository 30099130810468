'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class SiteFooter {
  constructor() {

    let footerAccordionBtn = $('.footer__accordion'),
    footerDirectory = $('.footer__directory');

    footerAccordionBtn.click(function(e) {
      e.preventDefault();

      $(this).toggleClass('active');
      footerDirectory.toggleClass('show');
    })

  }
}

'use strict';

import $ from 'jquery';
import { initFilters } from '../../../_scripts/_helper';

export default class NewsListing {
  constructor() {

    let newsListingContainer = $('.news-listing');

    if (newsListingContainer.length) {
      initFilters(newsListingContainer);
    }

  }
}

'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import {bp} from '../../../_scripts/_helper';
import 'sticky-kit';

export default class Sidebar {
  constructor() {

    // set the selector variables
    let $sidebar = $('.sidebar'),
    $exploreMore = $('.sidebar__explore', $sidebar),
    $sidebarMenu = $('.sidebar__parent-list', $sidebar),
    $subMenuIcon = $('.sidebar__submenuIcon', $sidebarMenu);


    if ($('.standard').length) {
      let $sidebarTopSpace = $('.standard').css('margin-top').replace('px', '');

      $sidebarTopSpace = parseInt($sidebarTopSpace);

      $(window).on('load', function() {
        // sidebar sitcky
        $sidebar.stick_in_parent({
          offset_top : $('header').outerHeight(),
          recalc_every: 1
        });

      });
    }


    /* =========================================
    >> Mobile and tablet sidebar menu
    ============================================ */
    enquire.register("screen and (max-width:"+bp.minDesktop+"px)", {
      match : function() {
        $exploreMore.on('click.mobileSidebar', function() {
          $(this).toggleClass('mbl-active');
          if($(this).hasClass('mbl-active')) {
            $sidebarMenu.slideDown();
          } else {
            $sidebarMenu.slideUp();
          }
        });
      },
      unmatch : function() {
        $exploreMore.off('click.mobileSidebar');
        $sidebarMenu.css('display', ''); // reset display property
      }
    });




  }
}

'use strict';

import $ from 'jquery';

export default class Accordion {
  constructor(selector=$('.accordion'), reinit=false) {
    let $accordion = selector;

    // reinit the accordion
    if(reinit) {
      $accordion.each(function() {
        $('.accordion__title', $accordion).off();
      });
    }

    // hide the accordion content
    function hideContent($item) {
      let $content = $item.find('> .accordion__content');
      $content.slideUp();
      $item.removeClass('is-active');
    }

    // show the accordion content
    function showContent($item) {
      let $content = $item.find('> .accordion__content'),
      $group = $item.parent('.accordion'),
      $activeItem = $group.find('.accordion__item.is-active');

      // Enable this if show one item at a time
      if ($activeItem.length) {
        hideContent($activeItem);
      }

      $content.stop().slideDown();
      $item.addClass('is-active');
    }

    // enable click handler for all accrodions
    $accordion.each(function() {
      let $this = $(this),
      $activeItem = $this.find('> .accordion__item.is-active');

      showContent($activeItem);

      $accordion.find('> .accordion__item > .accordion__title').on('click', function(e) {
        e.preventDefault();
        // !$(this).parent('.accordion__item').find('.child').length

        console.log('click');

        let $title = $(this),
        $parent = $title.parent('.accordion__item');

        if ($parent.hasClass('is-active')) {
          hideContent($parent);
        } else {
          showContent($parent);
        }

      });
    });

  }
}

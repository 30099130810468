'use strict';

import $ from 'jquery';
import 'sticky-kit';

export default class Contact {
  constructor() {
    if ($('.contact').length) {
      let $contactDepot = $('.contact__depot'),
        $contactNav = $('.contact__nav'),
        $contactRegions = $('a', $contactNav);

      $contactRegions.on('click', function() {
        $contactRegions.removeClass('active');
        $(this).addClass('active');
      });

      if ($contactDepot.length) {
        $contactDepot.stick_in_parent({
          offset_top: 90
        });
        $contactNav.stick_in_parent({
          offset_top: 150
        });
      } else {
        $contactNav.stick_in_parent({
          offset_top: 90
        });
      }
    }
  }
}

'use strict';

import $ from 'jquery';

import { initFilters } from '../../../_scripts/_helper';

export default class ResourceListing {
  constructor() {

    let resourceListingContainer = $('.resource-listing');

    if (resourceListingContainer.length) {
      initFilters(resourceListingContainer);
    }

  }
}

'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import {bp} from '../../../_scripts/_helper';

export default class SiteHeader {
  constructor() {

    let didScroll,
    header = $('.header'),
    lastScrollTop = 0,
    delta = 5,
    headerHeight = header.outerHeight();

    enquire.register("screen and (max-width: 1024px)", {

      // OPTIONAL
      // If supplied, triggered when a media query matches.
      match : function() {

        $(window).scroll(function(event) {
    			didScroll = true;
    		});

    		setInterval(function() {

    			if (didScroll) {
    				hasScrolled();
    				didScroll = false;
    			}

    		}, 250);

      }

    });



		function hasScrolled() {
			var st = $(window).scrollTop();
			// Make sure they scroll more than delta

			if(Math.abs(lastScrollTop - st) <= delta) {
				return;
			}

			if (st > lastScrollTop && st > headerHeight){
				// Scroll Down
        header.addClass('slideDown');

			} else {
				// Scroll Up
				if(st + $(window).height() < $(document).height()) {

          header.removeClass('slideDown');

				}

			}

			lastScrollTop = st;
		}


  }
}

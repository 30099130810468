'use strict';

import $ from 'jquery';

export default class SearchBox {
  constructor() {

    let desktopSearchBox = $('.search-box.desktop-only'),
    searchBtn = $('.search-button'),
    searchBtnText = $('.search-button .search-toggle-text'),
    searchBoxSearch = $('.search-box__search'),
    searchBoxInput = desktopSearchBox.find('.search-box__input'),
    queryParam = searchBoxInput.attr('name'),
    searchResultsPage = desktopSearchBox.data('destination'),
    overlayBg = $('.overlay-bg');

    let mobileSearchBox = $('.search-box.handheld-only'),
    mobileSearchTrigger = mobileSearchBox.find('button'),
    mobileSearchInput = mobileSearchBox.find('input');

    mobileSearchTrigger.click(function(event) {
      event.preventDefault();

      conductSearch(mobileSearchInput);
    });

    searchBoxSearch.click(function(event) {

      event.preventDefault();

      conductSearch(searchBoxInput);

    });

    searchBoxInput.on('keydown', function(e) {

      if (e.which == 13) {

        conductSearch($(this));

      }

    })

    $(document).mouseup(function(e) {
      var container = desktopSearchBox,
      target = $(e.target);
      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {

        if (!target.hasClass('search-toggle')) {
          window.emitter.emit('showSearchBox', 'hide');
          searchBtn.removeClass('active');
          searchBtnText.html('Search');
        } else {
          searchBtnText.html('Close');
        }
      }
    });

    searchBtn.on('click', function() {
      if (searchBtn.hasClass('active')) {
        searchBtnText.html('Close');
      } else {
        searchBtnText.html('Search');
      }
    });

    window.emitter.on('showSearchBox', function(event) {

      if(event == 'show') {
        desktopSearchBox.addClass('show');

        overlayBg.addClass('show');
      } else if (event == 'hide') {
        desktopSearchBox.removeClass('show');

        overlayBg.removeClass('show');
      } else if (event == 'toggle') {
        desktopSearchBox.toggleClass('show');

        overlayBg.toggleClass('show');
      }

    });

    // helper functions

    function conductSearch(input) {

      let searchValue = input.val(),
      newUrl = `${searchResultsPage}?${queryParam}=${searchValue}`;

      if (searchValue.length) {
        // execute search
        window.location.href = newUrl;
      }

    }

  }
}

'use strict';

import $ from 'jquery';
import 'readmore-js';
import enquire from 'enquire.js';

export default class LandingPage {
  constructor() {

    let imgListCards = $('.img-list-card').find('.col-3-md'),
    allListContents = imgListCards.find('.img-list__content');


    enquire.register("screen and (min-width: 1023px)", {

      // OPTIONAL
      // If supplied, triggered when a media query matches.
      match : function() {

        imgListCards.each(function(idx, each) {

          let $each = $(each),
          listItems = $each.find('li'),
          noOfItems = listItems.length;

          if (noOfItems > 4) {

            // to do readmore on this
            let imgListInfo = $each.find('.img-list__info'),

            collapsedHeight = 0;

            for (let i = 0; i < 4; i++) {

              let listItem = $(listItems[i]),
              listItemHeight = listItem.outerHeight();

              collapsedHeight += listItemHeight;

            }

            imgListInfo.readmore({
              speed: 300,
              collapsedHeight: collapsedHeight,
              moreLink: `<a href="#" class="img-list__more">View more</a>`,
              lessLink: `<a href="#" class="img-list__more">View less</a>`,
              startOpen: false
            });

          }
        });
      },

      // OPTIONAL
      // If supplied, triggered when the media query transitions
      // *from a matched state to an unmatched state*.
      unmatch : function() {

        allListContents.readmore('destroy');

      }

    });

  }
}

'use strict';

import $ from 'jquery';
// import easyAutocomplete from 'easy-autocomplete';
import '../../../_scripts/jquery.easy-autocomplete.min';
import { getUrlParameter, removeTrailingSlash } from '../../../_scripts/_helper';

export default class SearchFormResults {
  constructor() {

    let $searchForm = $('.search-form'),
    suggestionsArray;

    if ($searchForm.length) {

      let $searchBtn = $('.btn--search-form', $searchForm),
      $searchInput = $('.search-results-input', $searchForm),
      autocompleteInput = $('.search-results-input', $searchForm),
      $currentParam = getUrlParameter('query');

      // $searchInput.val($currentParam);

      if ($currentParam) {

        $searchInput.val($currentParam);

      }


      $searchInput.on('keydown', function(e) {

        if (e.which == 13) {
          searchParam($(this));
        }

      });

      $searchBtn.on('click', function(e) {
        e.preventDefault();

        searchParam($searchInput);

      });

      $searchInput.bind("enter",function(e){
        e.preventDefault();
      });

      // search function
      function searchParam(input) {

        let searchValue = input.val(),
        queryParam = input.attr('name') || 'query',
        currentUrl = window.location.origin + window.location.pathname,
        currentPathname = input.data('url'),
        idName = input.data('idName'),
        identifier = '';

        suggestionsArray.forEach(function(each, idx) {

          if (each.Title === searchValue) {
            identifier = each.Id;
          }

        });

        // removeTrailingSlash
        currentUrl = removeTrailingSlash(currentUrl, '/');

        let newUrl = `${currentUrl}?${queryParam}=${searchValue}&${idName}=${identifier}`;

        if (searchValue) {
          window.location.href = newUrl;
        }

      }

      let searchSuggestionsApi = $('.search-form').data('api');

      function autoSearch() {

        $.ajax({
          url: searchSuggestionsApi,
          method: 'GET',
          contentType: 'application/json'
        }).done(function(data) {

          suggestionsArray = data;

          let options = {
            data: data,
            getValue: "Title",
            list: {
              showAnimation: {
                type: "slide", //normal|slide|fade
                time: 400,
                callback: function() {}
              },
              hideAnimation: {
                type: "slide", //normal|slide|fade
                time: 400,
                callback: function() {}
              },
              match: {
                enabled: true
              }
            },
            template: {
              type: "custom",
              method: function(value, item) {
                return `<p>${value}</p>`;
              }
            }
          };

          autocompleteInput.easyAutocomplete(options);

        });
      }

      autoSearch();

    }

  }
}

'use strict';

import $ from 'jquery';
export default class Rte {
  constructor() {
    $('.rte table').each(function() {
        let $this = $(this);

        if (!$this.parents('.table').length) {
            $this.wrap('<div class="table"></div>');
        }
    });
  }
}

'use strict';

import $ from 'jquery';
import slick from 'slick-carousel';

export default class GallerySlider {
  constructor() {
    if($('.gallery-slider').length) {

      /*=======================================================
      >> Carousel setttings
      =========================================================*/
      function slickSettings() {
        return {
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ],
          nextArrow: `<button class="carousel-next" type="button"><i class="icon icon-angle-right"></i><span class="visuallyhidden">Button to view the next items of the carousel</span></button>`,
          prevArrow: `<button class="carousel-prev" type="button"><i class="icon icon-angle-left"></i><span class="visuallyhidden">Button to view the previous items of the carousel</span></button>`
        }
      }

      // Using the emitter function to call this from different pages
      window.emitter.on('initSlick', function() {
        $('.gallery-slider__carousel').slick(slickSettings());
      });

      // Initialize the slider
      window.emitter.emit('initSlick');
    }

  }
}

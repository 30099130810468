'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import 'slick-carousel';

export default class Notifications {
  constructor() {

    let notificationsSlick = $('.notifications__slick');

    $(window).on('load', function() {

      let notificationArrow = $('.notification-arrow');

      notificationArrow.click(function(e) {
        e.preventDefault();
      })
    });


    notificationsSlick.slick(slickSettings());


    function slickSettings() {
      return {
        infinite: false,
        speed: 1000,
        autoplaySpeed: 5000,
        autoplay: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        dotsClass: 'custom_paging',
        customPaging: function (slider, i) {
          return  (i + 1) + '/' + slider.slideCount;
        },
        nextArrow: '<button class="notification-next notification-arrow"><i class="icon icon-angle-right"></i><span class="visuallyhidden">Button to view the next items of the slideshow</span></button>',
        prevArrow: '<button class="notification-prev notification-arrow"><i class="icon icon-angle-left"></i><span class="visuallyhidden">Button to view the previous items of the slideshow</span></button>'
      }
    }
  }
}

'use strict';

import $ from 'jquery';
import { initFilters } from '../../../_scripts/_helper';

export default class EventListCard {
  constructor() {
    let eventListContainer = $('.event-list-section');

    if (eventListContainer.length) {
      initFilters(eventListContainer);
    }

  }
}

'use strict';

import $ from 'jquery';

export default class SearchButton {
  constructor() {

    let searchBtn = $('.search-button');

    searchBtn.click(function(e) {
      e.preventDefault();

      $(this).toggleClass('active');

      window.emitter.emit('showSearchBox', 'toggle');
    });

  }
}

'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class HomeBanner {
  constructor() {
    // $('.home-banner__slider').slick({
    //   slidesToShow: 1,
    //   arrows: false,
    //   fade: true,
    //   autoplay: true,
    //   autoplaySpeed: 6000
    // });
  }
}

'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class NewsContent {
  constructor() {

    let newsContentSlick = $('.news-content__cards');

    enquire.register("screen and (max-width:1024px)", {

      // OPTIONAL
      // If supplied, triggered when a media query matches.
      match : function() {

        newsContentSlick.slick({
          slidesToShow: 1,
          slideToScroll: 1,
          dots: false,
          arrows: false,
          speed: 400,
          centerMode: true,
          centerPadding: '15px',
          infinite: true,
          cssEase: 'ease',
        });

      },

      // OPTIONAL
      // If supplied, triggered when the media query transitions
      // *from a matched state to an unmatched state*.
      unmatch : function() {
        newsContentSlick.slick('unslick');
      },

      // OPTIONAL
      // If supplied, triggered once, when the handler is registered.
      setup : function() {},

      // OPTIONAL, defaults to false
      // If set to true, defers execution of the setup function
      // until the first time the media query is matched
      deferSetup : true,

      // OPTIONAL
      // If supplied, triggered when handler is unregistered.
      // Place cleanup code here
      destroy : function() {}

    });
  }
}

'use strict';

import $ from 'jquery';
export default class HamburgerMenuBtn {
  constructor() {
    let $mobileMenuIcon = $('.mobile-menu-icon');

    $mobileMenuIcon.on('click', function(event) {
      event.stopPropagation();
      $(this).toggleClass('is-active');
      if($(this).hasClass('is-active')) {
        window.emitter.emit('showMobileMenu', 'show');
      } else {
        window.emitter.emit('showMobileMenu', 'hide');
      }
    });
  }
}

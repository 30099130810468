'use strict';

import $ from 'jquery';
import flatpickr from "flatpickr";
import selectize from 'selectize';


import { areAllSelectsFilled } from '../../../_scripts/_helper';

export default class PetImportRequirementCalculator {
  constructor() {

    let dateOfArrivalInput = $('#dateOfArrival').find('input'),
    petImportRequirementObj = {};

    dateOfArrivalInput.flatpickr({
      altInput: true,
      altFormat: "j M Y",
      dateFormat: "d-m-Y",
    });


    let primaryForm = $('.pet-import-requirement-calculator__primary'),
    secondaryForm = $('.pet-import-requirement-calculator__secondary'),
    primarySearch = primaryForm.find('.pet-import-requirement-calculator__search'),
    primaryCancel = primaryForm.find('.pet-import-requirement-calculator__cancel'),
    bothForms = $('.pet-import-requirement-calculator__form'),
    petImportRequirementContainer = $('.pet-import-requirement-calculator'),
    categoryToShow = petImportRequirementContainer.data('showCategory'),
    resultPageUrl = petImportRequirementContainer.data('resultPage'),
    allSelects = petImportRequirementContainer.find('.pet-import-requirement-calculator__select'),
    validityOfLastVaccination = $('#validityOfLastVaccination'),
    validityOfLastVaccinationSelect = validityOfLastVaccination.find('select');

    let secondarySearch = secondaryForm.find('.pet-import-requirement-calculator__search'),
    secondaryCancel = secondaryForm.find('.pet-import-requirement-calculator__cancel');



    // UPDATE DATA OBJECT WITH EMPTY STRINGS
    initializeInitialObject();

    allSelects.selectize({
      create: false,
      persist: false,
      onChange: function(val) {

        let value = val,
        $this = this.$input,
        paramName = $this.closest('.pet-import-requirement-calculator__each').attr('id');


        if (paramName == 'countryOfExport') {

          value = JSON.parse(value);

          for (let key in value) {

            petImportRequirementObj[key] = value[key];

          }

        } else {

          petImportRequirementObj[paramName] = value;

        }

      }
    });


    primarySearch.click(function(event) {
      event.preventDefault();

      let primarySelects = primaryForm.find('select.pet-import-requirement-calculator__select'),
      dateOfArrivalError = dateOfArrivalInput.siblings('.error'),
      dateOfArrivalVal = dateOfArrivalInput.val();

      let canSubmit = areAllSelectsFilled(primarySelects);

      if (dateOfArrivalVal.length == 0) {
        dateOfArrivalError.show();
        canSubmit = false;
      } else {
        dateOfArrivalError.hide();

        // add date to object
        let dateParam = dateOfArrivalInput.parents('.pet-import-requirement-calculator__each').attr('id');

        petImportRequirementObj[dateParam] = dateOfArrivalVal;

      }

      let currentCountryCategory = petImportRequirementObj.category;


      if (canSubmit) {

        if (currentCountryCategory === categoryToShow) {

          handleSubmission();

        } else {

          bothForms.removeClass('show');
          secondaryForm.addClass('show');

        }
      }

    });

    // no validation for secondarySearch required
    secondarySearch.click(function(event) {
      event.preventDefault();

      // validate
      let secondaryTextInput = secondaryForm.find('input[type="number"]'),
      secondaryTextInputVal = secondaryTextInput.val();

      if (secondaryTextInputVal == "") {
        secondaryTextInputVal = 0;
      }

      let paramName = secondaryTextInput.parents('.pet-import-requirement-calculator__each').attr('id');

      // update time taken between taking of blood sample and getting serology results
      petImportRequirementObj[paramName] = secondaryTextInputVal;

      // update validity of last vaccination
      petImportRequirementObj[validityOfLastVaccination.attr('id')] = validityOfLastVaccinationSelect.val();

      handleSubmission();

    });


    /** HELPER FUNCTIONS **/
    function initializeInitialObject() {

      let allFields = $('.pet-import-requirement-calculator__each');

      allFields.each(function(i, each) {

        let $each = $(each),
        paramName = $each.attr('id');

        petImportRequirementObj[paramName] = '';

      });

    }


    function handleSubmission() {
      let url = window.location.origin;

      url += `${resultPageUrl}/`;

      $.each(petImportRequirementObj, function(key, value) {

        url += `&${key}=${value}`;

      });

      url = url.replace('/&', '/?');

      window.location.href = url;
    }


  }
}

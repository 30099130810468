'use strict';

import $ from 'jquery';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import selectize from 'selectize';
import TypeAhead from 'jquery-typeahead';
import flatpickr from "flatpickr";

let removeTrailingSlash = function(str, char) {

  if (str[str.length -1] == char) {
    str = str.slice(0, -1);
  }

  return str;
}

let preventSelectizeTyping = function() {
  $(".selectize-input input").attr('readonly','readonly');
};


let bp = {
  "maxMobile": 480,
  "Tablet": 768,
  "minDesktop": 1024
};

let debounce = function(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

let areAllSelectsFilled = function(selects) {

  let valid = true;

  selects.each(function(idx, each) {

    let $each = $(each),
    value = $each.val(),
    errorMessage = $each.parent().find('.error');

    if (!value.length) {

      errorMessage.show();

      valid = false;

    } else {
      errorMessage.hide();
    }

  });

  return valid;

}

let getUrlParameter = function(sParam) {
  var sPageURL = window.location.search.substring(1),
  sURLVariables = sPageURL.split('&'),
  sParameterName,
  i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
};

let initFilters = function(parent) {

  let filterBtn = parent.find('.filter-button__search'),
  clearBtn = parent.find('.filter-button__clear'),
  url = '',
  paginationContainer = $('.pagination'),
  paginationBtn = parent.find('.pagination__btn'),
  paginationViewSelect = parent.find('.pagination-view__select'),
  paginationViewParamName = paginationViewSelect.data('param'),
  itemsPerPage = paginationViewSelect.val(),
  allSelectize = parent.find('.selectize-select'),
  filterResultObj = {},
  charLimit = 22,
  activePageBtn = $('.active', paginationContainer).find('.pagination__btn'),
  currentPage = activePageBtn.data('page');


  /** FOR HTML EVENTS TO REFLECT PARAMS IN URL (IF GIVEN) **/
  retrieveParams();

  // init selectize
  allSelectize.selectize({
    create: false,
    persist: false,
    onInitialize: function() {

      let $this = this.$input,
      param = $this.data('select'),
      text = $this.text(),
      displaySelect = $this.siblings().find('.item'),
      currentVal = $this.val();

      let paramVal = getUrlParameter(param);

      if (paramVal) {
        // if param is provided, set value in select
        this.setValue(paramVal);
      } else {

        // value is all if it doesnt have param in url
        filterResultObj[param] = currentVal;

      }

      if (text.length > charLimit) {

        text = text.substring(0, charLimit);
        text += '...';

      }

      displaySelect.text(text);

    },
    onChange: function(value) {

      let $this = this.$input,
      param = $this.data('select'),
      text = $this.text(),
      displaySelect = $this.siblings().find('.item');

      if (text.length > charLimit) {

        text = text.substring(0, charLimit);
        text += '...';

      }

      displaySelect.text(text);

      filterResultObj[param] = value;
    }
  });

  // set page number
  setPageNum(currentPage);
  setItemsPerPage(itemsPerPage);

  /** END OF FILTERING **/

  /** EVENTS **/

  clearBtn.click(function(event) {
    event.preventDefault();

    // empty out filterResultObj
    filterResultObj = {
      page: 1
    };

    let urlToRedirect = window.location.href.split('?');
    urlToRedirect = urlToRedirect[0];

    window.location.href = urlToRedirect;
  });


  filterBtn.click(function(event) {
    event.preventDefault();

    filterResultObj.page = 1;

    filterResults(filterResultObj); //pageNum starts with 1

  });


  paginationBtn.click(function(event) {
    event.preventDefault();

    let pageNumClicked = $(this).data('page'),
    currentPage = getUrlParameter(paginationViewParamName);

    setPageNum(pageNumClicked);

    if (pageNumClicked != currentPage) {

      filterResults(filterResultObj);

    }

  });

  paginationViewSelect.change(function() {

    let value = $(this).val();

    setItemsPerPage(value);

    filterResults(filterResultObj);

  });

  /** HELPER FUNCTIONS **/

  function setItemsPerPage(number) {
    filterResultObj[paginationViewParamName] = number;
  };

  function setPageNum(page) {
    filterResultObj.page = page;
  };

  function retrieveParams() {
    let url = window.location.href;

    url = url.split('?');

    // if have params
    if (url.length > 1) {

      let params = url[1];

      let paramsArray = params.split('&');

      $.each(paramsArray, function(idx, each) {

        let param = each.split('=');

        if (param[0] == paginationViewParamName) {

          itemsPerPage = param[1];
          paginationViewSelect.val(param[1]);

        }

        filterResultObj[param[0]] = param[1];

      });
    }

  }


  // helper
  function filterResults(obj) {

    let url = window.location.href.split('?');

    url = url[0]; // take everything besides the params

    Object.keys(obj).forEach(function(key, idx) {

      let val = obj[key];


      if (idx === 0) {
        url += `?${key}=${val}`;
      } else {
        url += `&${key}=${val}`;
      }

    });

    window.location.href = url;
  }

}

let retrieveOptions = function(endpoint, container, keyName) {

  $.ajax({
    type: 'GET',
    url: endpoint,
    contentType: 'json',
    success: function(data) {

      let subjectHTML = '<option>Please select the relevant subject</option>';
      renderOptions(data, 'Subject', container, subjectHTML);

    },
    error: function(error) {
      console.log(error);
    }
  })

};

let renderOptions = function(array, keyName, container, defaultOption) {


  let optionsHtml = defaultOption,
  parentContainer = container.closest('.feedback-form__each');

  if (array && array.length) {

    array.forEach(function(each) {
      optionsHtml += `<option value="${each[keyName]}">${each[keyName]}</option>`;
    });

    // place html in container
    container.html(optionsHtml);
    // then show its parent
    showContainer(parentContainer);
    addClassToInput(parentContainer, 'feedback-form__element');

  } else {

    // hide the parent because the array doesnt exist
    hideContainer(parentContainer);

  }

}

let addClassToInput = function(container, className) {
  container.find('.feedback-form__preliminary').addClass(className);
}

let removeClassFromInput = function(container, className) {
  container.find('.feedback-form__preliminary').removeClass(className);
}

let showContainer = function(container) {
  container.addClass('show');
}

let hideContainer = function(container) {

  container.removeClass('show');

}

let defaultOptionGenerator = function(name) {
  return `<option>Please select the relevant ${name}</option>`;
};

let initialiseMap = function() {
  /** MAP **/
  let center = L.bounds([1.56073, 104.11475], [1.16, 103.502]).getCenter(),
  map = L.map('map', {
    editable: true
  }).setView([center.x, center.y], 12);

  L.tileLayer('https://maps-{s}.onemap.sg/v3/Grey/{z}/{x}/{y}.png', {
    attribution: '<img src="https://docs.onemap.sg/maps/images/oneMap64-01.png" style="height:20px;width:20px;"/> OneMap | Map data © contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>',
    maxZoom: 18,
    minZoom: 11,
    id: 'mapbox.streets'
  }).addTo(map);

  map.setMaxBounds([[1.56073, 104.1147], [1.16, 103.502]]);

  //global layers and layergroups
  let markersLayer=L.layerGroup().addTo(map);

  // Typeahead for autocomplete and location search
  let $dropdown = $('.typeahead__autocomplete'),
  selectDropPin = $('.js-select-droppin'),
  $mapOptionsParent = $('.map-input'),
  $mapInput = $('.map-input'),
  startMarker = null,
  SEARCH_API_URL='https://developers.onemap.sg/commonapi/search?returnGeom=Y&getAddrDetails=Y&pageNum=1',
  iconUrl = '../Cwp/assets/AVS/images/markers/end-marker.png';

  // icons
  let defaultIcon = L.icon({
    iconUrl: iconUrl,
    iconSize: [20, 29] //Size of the icon image in pixels.
  });

  // function for dropping pin on map
  selectDropPin.on('click mouseenter', function(event) {
    event.preventDefault();

    $('.leaflet-container').addClass('droppin-cursor');

    $mapInput.removeClass('showDropdown').addClass('dropPin');

    let droppin = L.icon({
      iconUrl: iconUrl,
      iconSize: [25, 41]
    });

    let marker = map.editTools.startMarker(null, {
      icon: droppin
    });

    clearLayers();

    marker.addTo(markersLayer);
    // markersLayer.addTo(map);

    marker.on('moveend', function(e) {
      let location = createLatLng(e.target.getLatLng());
      getAddressFromLocation(location);

      let latLongLocation = location.split(',');
      let latLong = L.latLng(latLongLocation[0], latLongLocation[1]);

      map.flyTo(latLong, 16, {duration: 0.25});
    });
  });

  map.on('editable:drawing:end', function(e) {
    let location = createLatLng(e.layer.getLatLng());
    getAddressFromLocation(location);
    let latLongLocation = location.split(',');
    let latLong = L.latLng(latLongLocation[0], latLongLocation[1]);

    map.flyTo(latLong, 16, {duration: 0.25});

    $('.leaflet-container').removeClass('droppin-cursor');
  });

  $dropdown.on('blur', function() {
    setTimeout(function() {
      $mapOptionsParent.removeClass('showDropdown');
    }, 100);
  })

  $dropdown.on('keypress', function() {
    $mapOptionsParent.removeClass('showDropdown');
  })

  $dropdown.typeahead({
    minLength: 2,
    order: "asc",
    dynamic: true,
    display:['ADDRESS'],
    source: {
      results: {
        ajax: {
          url: SEARCH_API_URL,
          data: {
            searchVal: '{{query}}'
          },
          path: 'results'
        }
      }
    },
    selector: {
      /*
      These are plugin specific hooks. You may use them, but need to override default rules and create
      your own based on the selectors that you will change.
      TO DO: create custom hooks and css so the plugin usage is not exposed to public.
      */
      container: "typeahead__container",
      result: "typeahead__result",
      list: "typeahead__list",
      group: "typeahead__group",
      item: "typeahead__item",
      empty: "typeahead__empty",
      display: "typeahead__display",
      query: "typeahead__query",
      filter: "typeahead__filter",
      filterButton: "typeahead__filter-button",
      dropdown: "typeahead__dropdown",
      dropdownItem: "typeahead__dropdown-item",
      button: "typeahead__button",
      backdrop: "typeahead__backdrop",
      hint: "typeahead__hint",
      cancelButton: "typeahead__cancel-button"
    },
    debug: false,
    callback: {
      onClickAfter: function(node, a, item, event) {

        if(item){

          clearLayers();

          let position=L.latLng(item.LATITUDE, item.LONGITUDE);
          showPosition(position, item.ADDRESS);

          //update the hidden input fields for block no, building name and others
          var block = '';

          if (item.BLK_NO) {
            block = `BLK ${item.BLK_NO}`
          }
          var streetName = '';
          if (item.ROAD_NAME) {
            streetName = `${item.ROAD_NAME}`;
          }
          var geoX = `${item.X}`;
          var geoY = `${item.Y}`;
          var postalCode = '';
          if (item.POSTAL) {
            postalCode = `${item.POSTAL}`;
          }
          var buildingName = '';
          if (item.BUILDING) {
            buildingName = `${item.BUILDING}`;
          }

          $('#buildingName').val(buildingName);
          $('#block').val(block);
          $('#road').val(streetName);
          $('#xCoord').val(geoX);
          $('#yCoord').val(geoY);
          $('#postalCode').val(postalCode);

        }
        $mapInput.removeClass('showDropdown');
      },
      onCancel:function (node, event){
        clearLayers();
        $('#buildingName').val('');
        $('#block').val('');
        $('#road').val('');
        $('#xCoord').val('');
        $('#yCoord').val('');
        $('#postalCode').val('');
      }
    }
  });

  // Show dropdown for map on focus
  $('input', $mapInput).on('focus', function(e){
    $mapInput.addClass('showDropdown');
  });

  // Hide dropdown for map on focus
  $('input', $mapInput).on('input', function(e){
    $mapInput.removeClass('showDropdown');
  });

  function clearLayers(){
    markersLayer.clearLayers();
    $mapInput.removeClass('dropPin');
  }

  function showPosition(position, popUpContent) { //position is a L.latLng object
    // clearLayers();
    let marker = addMarker(position, popUpContent);
    map.panTo(position);
    map.flyTo(position, 16, {duration: 0.25})
  }

  function addMarker(position, tooltip) {
    let marker = new L.Marker(position,
      {
        icon: defaultIcon, //defaultIcon is a L.icon. It can also be a L.divIcon(defaultDivIcon).
        bounceOnAdd: false,
        title: tooltip
      })
      .addTo(markersLayer);

      return marker;
    }

    var getAddressFromLocation = function(location) {

      var locationDetailAPI = 'https://developers.onemap.sg/privateapi/commonsvc/revgeocode?location={location}&token=';
      var location_params = '&buffer=200&addressType=all&otherFeatures=Y';
      var API_KEY = $('#onemapAPIToken').data('apiToken');

      var locationURL = locationDetailAPI.replace('{location}', location) + API_KEY + location_params;

      $.getJSON(locationURL, function(){
      }).done(function(data){
        var block, road, lat, lng, address;

        createCaseDetailObj(data.GeocodeInfo);

        if(data.error) {
          alert(data.error);
        } else {

          if(data.GeocodeInfo[0] !== undefined) {
            if(typeof data.GeocodeInfo[0].BLOCK !== "undefined") {
              block = data.GeocodeInfo[0].BLOCK;
            }

            if(data.GeocodeInfo[0].ROAD) {
              road = data.GeocodeInfo[0].ROAD;
            }

            if(data.GeocodeInfo[0].LATITUDE) {
              lat = data.GeocodeInfo[0].LATITUDE;
            }

            if(data.GeocodeInfo[0].LONGITUDE) {
              lng = data.GeocodeInfo[0].LONGITUDE;
            }

            if(typeof block !== "undefined") {
              address = block + " " + road;
            } else {
              address = road;
            }

            let locationObj = {
              address: address,
              building: data.GeocodeInfo[0].BUILDINGNAME,
              lat: lat,
              lng: lng
            }

            $('#street').val(locationObj.address);

          } else {
            alert("No address information found.");
          }
        }

      }).fail(function(err){
        alert("The routing services provided by OneMap is currently unavailable. Please try again later.");
      });

      function createCaseDetailObj(locations) {

        var chosen = locations[0];

        // var block = "BLK " + chosen.BLOCK;
        var block = '';
        if (chosen.BLOCK) {
          block = `BLK ${chosen.BLOCK}`
        }
        var streetName = '';
        if (chosen.ROAD) {
          streetName = `${chosen.ROAD}`;
        }
        var geoX = `${chosen.XCOORD}`;
        var geoY = `${chosen.YCOORD}`;
        var postalCode = '';
        if (chosen.POSTALCODE) {
          postalCode = `${chosen.POSTALCODE}`;
        }
        var buildingName = '';
        if (chosen.BUILDINGNAME) {
          buildingName = `${chosen.BUILDINGNAME}`;
        }


        $('#buildingName').val(buildingName);
        $('#block').val(block);
        $('#road').val(streetName);
        $('#xCoord').val(geoX);
        $('#yCoord').val(geoY);
        $('#postalCode').val(postalCode);

      }
    };

    /** END OF MAP **/
  }


  let generateForm = function(endpoint, container, subjectName, subtopicName, caseDetails, natureOfOffense) {

    let parentContainer = container.parent(); // step3
    showContainer(parentContainer);

    let caseDetailsHint = caseDetails || '';

    let data = {
      subjectName: subjectName,
      subtopicName: subtopicName
    };


    $.ajax({
      type: 'GET',
      url: endpoint,
      dataType: 'json',
      data: data,
      contentType: 'application/json',
      success: function(data) {

        let fields = data.Fields,
        fieldsHtml = '';

        fields.forEach(function(each, idx) {

          let fieldLabel = each.FieldLabel,
          fieldType = each.FieldType,
          fieldName = each.FieldName,
          required = each.Required,
          errorMessage = each.ErrorMessage,
          options,
          textLimit;

          let requiredClass = required.length ? "required" : "";

          let asteriskHtml = required.length ? `
          <span>*</span>` : '';

          switch(fieldType) {

            case 'date':
            let dateHtml = `
            <div class="feedback-form__field">
            <label class="feedback-form__label" for="${fieldName}">
            ${fieldLabel}
            ${asteriskHtml}
            </label>
            <div class="feedback-form__input">
            <input type="text" data-param="${fieldName}" name="${fieldName}" class="feedback-form__element feedback-form__date ${requiredClass}" data-error="${errorMessage}"/>
            <span class="feedback-form__error"></span>
            </div>
            </div>`;

            fieldsHtml += dateHtml;

            break;

            case 'time':

            let timeHtml = `
            <div class="feedback-form__field">
            <label class="feedback-form__label" for="${fieldName}">
            ${fieldLabel}
            ${asteriskHtml}
            </label>
            <div class="feedback-form__input">
            <input type="text" data-param="${fieldName}" name="${fieldName}" class="feedback-form__element feedback-form__time ${requiredClass}" data-error="${errorMessage}"/>
            <span class="feedback-form__error"></span>
            </div>
            </div>`;

            fieldsHtml += timeHtml;

            break;

            case 'select':

            options = each.Options;

            let optionsHtml = '';

            if (fieldName === 'NatureOfOffense') {

              optionsHtml = `<option value="">Please select relevant nature of offense</option>`;

              options = natureOfOffense;

            } else {

              options = each.Options;

            }

            options.forEach(function(option, i) {

              let optionText = option.Text,
              isNatureOther = option.IsNatureOther || '';

              optionsHtml += `<option value="${optionText}" data-other="${isNatureOther}">${optionText}</option>`;

            });

            let selectHtml = `
            <div class="feedback-form__field">
            <label class="feedback-form__label">
            ${fieldLabel}
            ${asteriskHtml}
            </label>
            <div class="feedback-form__input">
            <select name="${fieldName}" data-param="${fieldName}" class="feedback-form__element ${requiredClass}" data-error="${errorMessage}">
            ${optionsHtml}
            </select>
            <span class="feedback-form__error"></span>
            </div>
            </div>`;

            if (fieldName === 'NatureOfOffense') {
              selectHtml += `
              <div id="${fieldName}Other" class="feedback-form__field hide">
              <label class="feedback-form__label">
              ${fieldLabel} (Other)
              ${asteriskHtml}
              </label>

              <div class="feedback-form__input">
              <input type="text" data-param="${fieldName}Other" name="${fieldName}Other" class="feedback-form__element" data-error="Please enter a valid value." maxlength="300"/>
              <span class="feedback-form__error"></span>
              </div>
              </div>`
            };

            fieldsHtml += selectHtml;

            break;

            case 'text':

            let textHtml = renderInput(fieldLabel, fieldType, fieldName, requiredClass, errorMessage, asteriskHtml)
            fieldsHtml += textHtml;

            break;

            case 'email':

            let emailHtml = renderInput(fieldLabel, fieldType, fieldName, requiredClass, errorMessage, asteriskHtml)
            fieldsHtml += emailHtml;

            break;

            case 'number':

            let phoneHtml = renderInput(fieldLabel, fieldType, fieldName, requiredClass, errorMessage, asteriskHtml)

            fieldsHtml += phoneHtml;

            break;

            case 'map':

            fieldsHtml += `
            <div class="feedback-form__field ${requiredClass}">
            <label class="feedback-form__label">
            ${fieldLabel}
            ${asteriskHtml}
            </label>
            <div class="feedback-form__input">
            <div id="${fieldType}" class="feedback-form__map"></div>
            <div class="typeahead">
            <div class="typeahead__container">
            <div class="typeahead__field">
            <div class="typeahead__query">
            <div class="map-input">
            <label for="street">The location is at/near to:</label>
            <input class="typeahead__autocomplete feedback-form__element ${requiredClass || ''}" name='${fieldName}' data-param="${fieldName}" placeholder='Enter street name or postal code' id="street" autocomplete='off' type='search' data-error="${errorMessage}"/>
            <span class="feedback-form__error"></span>

            <div class="map-options">
            <a href="#" class="js-select-droppin">
            <span class="marker-icon"><i class="icon icon-droppin icon-2x"></i></span>
            Drop pin on map
            </a>
            </div>
            <input class="feedback-form__address" name="BuildingName" id="buildingName" type="hidden" value="" />
            <input class="feedback-form__address" name="Block" id="block" type="hidden" value="" />
            <input class="feedback-form__address" name="Road" id="road" type="hidden" value="" />
            <input class="feedback-form__address" name="XCoord" id="xCoord" type="hidden" value="" />
            <input class="feedback-form__address" name="YCoord" id="yCoord" type="hidden" value="" />
            <input class="feedback-form__address" name="PostalCode" id="postalCode" type="hidden" value="" />
            </div>
            </div>
            </div>
            </div>
            <div class="feedback-form__additionalinfo">
            <label>Any additional information?</label>
            <input type="text" name="IncidentLocationDescription" placeholder="Near petrol station, behind lamppost...">
            </div>
            </div>
            </div>
            </div>`;

            break;

            case 'textarea':

            let maxLength = each.Limit;

            let textareaHtml = `
            <div class="feedback-form__field">
            <label class="feedback-form__label">
            ${fieldLabel}
            ${asteriskHtml}
            </label>
            <div class="feedback-form__input">
            <textarea name="${fieldName}" placeholder="${caseDetailsHint}" data-param="${fieldName}" maxlength="${maxLength}" class="feedback-form__element ${requiredClass}" data-error="Please enter a value for ${fieldLabel}" data-error="${errorMessage}"></textarea>
            <span class="feedback-form__error"></span>
            </div>
            </div>`;

            fieldsHtml += textareaHtml;

            break;

            case 'radio':

            let radioOptions = each.Options,
            inputHtml = '';

            // THEN APPEND BOTH OPTIONS AFTER
            radioOptions.forEach(function(option, i) {

              let radioOption = option.Text;

              // give checked property to the first input
              if (i == 0) {

                inputHtml += `
                <div class="feedback-form__radio">
                <input type="radio" id="${radioOption}" name="${fieldName}" data-param="${fieldName}" value="${radioOption}" class="feedback-form__element" checked />
                <label for="${radioOption}">${radioOption}</label>
                </div>`;

              } else {

                inputHtml += `
                <div class="feedback-form__radio">
                <input type="radio" id="${radioOption}" name="${fieldName}" data-param="${fieldName}" class="feedback-form__element" value="${radioOption}" />
                <label for="${radioOption}">${radioOption}</label>
                </div>`;

              };

            });

            // DO THE LABEL FIRST
            let radioHtml = `
            <div class="feedback-form__field">
            <label class="feedback-form__label">${fieldLabel}</label>
            <div class="feedback-form__input">
            ${inputHtml}
            </div>
            </div>`;

            fieldsHtml += radioHtml

            break;

            case 'file':

            let uploadHtml = '';

            for (let i = 0; i < 5; i++) {

              let showClass = i == 0 ? 'show' : '',
              takeAnotherHtml =  i == 4 ? '' : `<a href="#" class="feedback-form__uploadmore">Upload another file</a>`;

              uploadHtml += `
              <div class="feedback-form__file ${showClass}">
              <input type="file" name="${fieldName+i}" id="${fieldName+i}" />
              <div class="feedback-form__container">
              <span class="icon icon-camera"></span>
              <span class="feedback-form__filename"></span>
              </div>
              <div class="feedback-form__size">
              <span>Max file size is 9MB</span>
              </div>
              ${takeAnotherHtml}
              </div>`;

            }

            let fileHtml = `
            <div class="feedback-form__field">
            <label class="feedback-form__label">
            ${fieldLabel}
            ${asteriskHtml}
            </label>
            <div class="feedback-form__input">
            ${uploadHtml}
            </div>
            </div>`;

            fieldsHtml += fileHtml;

            break;

          }
        });

        container.html(fieldsHtml);

        $.fn.inputFilter = function(inputFilter) {
          return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
            if (inputFilter(this.value)) {
              this.oldValue = this.value;
              this.oldSelectionStart = this.selectionStart;
              this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty("oldValue")) {
              this.value = this.oldValue;
              this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            }
          });
        };

        /** HANDLE MAXLENGTH FOR DOG LICENCE NUMBER AND REFERENCE NUMBER **/
        $('.feedback-form__element[name="ReferenceNumber"]').attr('maxlength', 100);

        $('.feedback-form__element[name="DogLicenceNumber"]').attr('maxlength', 10);

        $('.feedback-form__element[name="Phone"]').inputFilter(function(value) {
          return /^\d*$/.test(value);
        });

        $('.feedback-form__element[name="Phone"]').attr('maxlength', 20);



        /** HANDLE DATEPICKER **/
        $('.feedback-form__date').flatpickr({
          altInput: true,
          altFormat: "j M Y",
          dateFormat: "d-m-Y",
          maxDate: 'today'
        });

        $('.feedback-form__time').flatpickr({
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
        });


        if ($('#map').length) {
          initialiseMap();
        }

      },
      error: function(error) {
        console.log(error);
      }
    });


    function renderInput(label, type, name, className, error, asteriskMarkup) {
      return `
      <div class="feedback-form__field">
      <label class="feedback-form__label" for="${name}">
      ${label}
      ${asteriskMarkup}
      </label>
      <div class="feedback-form__input">
      <input type="${type}" data-param="${name}" name="${name}" class="feedback-form__element ${className}" data-error="${error}"/>
      <span class="feedback-form__error"></span>
      </div>
      </div>`;
    }

  }

  // function to create lat and lng
  let createLatLng = (location) => {
    return location.lat + "," + location.lng;
  }

  let retrieveFormData = function() {

    let visibleForms = $('.feedback-form__each.show'),
    formData = {
      subject: '',
      subtopic: '',
      animalType: '',
      speciesWildAnimal: '',
      primaryIssue: ''
    };

    visibleForms.each(function(idx, each) {

      let $each = $(each),
      selectEle = $each.find('select'),
      paramName = selectEle.data('param'),
      val = selectEle.val();

      formData[paramName] = val;
    });

    return formData;

  }


  let validateEmail = function(email) {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  let validatePhoneNumber = function(entry) {
    if (entry.value == "") {
      return false;
    }
    else if (!(entry.length == 8)) {
      return false;
    }
    else return true;
  }



  export { bp, debounce, initFilters, getUrlParameter, areAllSelectsFilled, preventSelectizeTyping, retrieveOptions, renderOptions, hideContainer, showContainer, defaultOptionGenerator, generateForm, retrieveFormData, addClassToInput, removeClassFromInput, validateEmail, removeTrailingSlash, validatePhoneNumber };

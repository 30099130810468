'use strict';

import $ from 'jquery';

export default class Sitemap {
  constructor() {

    // hybrid model for arranging sitemap alignment
    var eachSitemapItem = $('.sitemap__each'),
    noOfItems = eachSitemapItem.length;

    // if (testArithmetic(noOfItems)) {
    //
    //   let lastItem = eachSitemapItem.last();
    //   lastItem.addClass('col-md-offset-4');
    //
    // }

    // to test if number is 3n-2 (4, 7, 10)
    function testArithmetic(number) {

      var reduceByOne = number - 1;

      if (reduceByOne % 3 === 0) {
        return true;
      } else {
        return false;
      }
    }
  }
}
